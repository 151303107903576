import { ContactInformation } from "./contact-information"

export class Users {
	Id: number;
	ContactInformationId: number;
	UserName: string;
	Password: string;
	RoleId: number;
	IsEmployee: boolean;
	IsHashedPassword: boolean;
	PasswordResetRequired: boolean;
	SendNewReleases: boolean;
	SendHoldReminders: boolean;
	SendVideoReminders: boolean;
	PasswordResetGuid: string;
	PasswordResetExpires: string;
	IsDeleted: boolean;
	LastLogin: string;

	ContactInformation: ContactInformation;
	editBtn: string;
	deleteBtn: string;
	friendlyName: string;
	role: string;
	name: string;
	isDisabled: boolean;
	lastLoginView: string;
	isSelected: boolean;


	constructor(contactInformationId?, roleId?, userName?, isEmployee?, isDeleted?) {
		this.ContactInformationId = contactInformationId;
		this.RoleId = roleId;
		this.UserName = userName;
		this.IsEmployee = isEmployee;
		this.IsDeleted = isDeleted;
	}
}