import { Component, AfterViewInit, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CanvasService, CEventsService } from './_services';
import { Layer } from '../_models';
import { FeedService, LayersService, UndoRedoService, VideoControlsService } from '../_services';

@Component({
	selector: 'me-canvas-wrapper',
	templateUrl: './canvas-wrapper.component.html',
	styleUrls: ['./canvas-wrapper.component.scss']
})
export class CanvasWrapperComponent implements AfterViewInit, OnInit, OnDestroy {
	@ViewChild('canvasContainer') canvasContainer: ElementRef;

	ctx: CanvasRenderingContext2D;

	public contentMarginLeft: number;
	public src: string;
	private subs: Subscription[] = [];

	constructor(
		private canvasService: CanvasService,
		private cEventsService: CEventsService,
		private feedService: FeedService,
		private layersService: LayersService,
		private undoRedoService: UndoRedoService,
		private videoControlsService: VideoControlsService
	) {
		this.onCreateLayerClick();
		this.onImageLoadComplete();
		this.onLayerDelete();
		this.onLayerReorder();
		this.onTimelineLayerSelect();

		this.cEventsService.subs.push(
			this.cEventsService.textChanged.pipe(debounceTime(600)).subscribe(() => {
				this.undoRedoService.recordState('Text changed');
			}),
			this.cEventsService.feedObjChanged.pipe().subscribe((feedTextbox) => {
				this.feedService.textToBase64$(feedTextbox).subscribe((res: { layerId: number; base64: string }) => {
					let layer = this.layersService.layers.find((layer) => layer.id === res.layerId);
					layer.previewUrl = res.base64;

					//Add updated image
					this.feedService.addImage$(layer, feedTextbox).subscribe(() => {
						let imageObjs = this.canvasService.canvas.getObjects().filter((obj) => obj.layerId === feedTextbox.layerId && obj.name === 'image');

						imageObjs.forEach((obj, index) => {
							//Remove all except the last image in the array (last image is latest)
							if (index < imageObjs.length - 1) {
								this.canvasService.canvas.remove(obj);
							}
						});
					});
				});
			})
		);
	}

	ngOnInit(): void {
		this.undoRedoService.past = [];
		this.undoRedoService.index = -1;
		this.contentMarginLeft = -this.canvasService.canvasSize().width / 2;

		//Prevent select object from automatically going to front of stack
		this.canvasService.canvas.preserveObjectStacking = true;
		this.cEventsService.enableObjectSnapping();
		this.cEventsService.onObjectMoving();
		this.cEventsService.onObjectChangeEnd();
		this.cEventsService.onObjectScale();
		this.cEventsService.onTextChanged();
		this.cEventsService.onSelectionCreated();
		this.cEventsService.onSelectionUpdated();
		this.cEventsService.onSelectionCleared();
		this.layersService.onMouseUp(this.canvasService.canvas);

		window.addEventListener('resize', () => {
			if (this.canvasContainer) {
				this.canvasService.canvasContainerWidth = this.canvasContainer.nativeElement.offsetWidth;
				this.cEventsService.onWindowResize();
			}
		});
	}

	ngAfterViewInit() {
		this.canvasService.canvasContainerWidth = this.canvasContainer.nativeElement.offsetWidth;
	}

	onCreateLayerClick(): void {
		this.subs.push(
			this.layersService.createLayerClicked$.subscribe((layer: Layer) => {
				this.canvasService.addObj(layer);
			})
		);
	}

	onImageLoadComplete(): void {
		this.subs.push(
			this.layersService.imageLoadComplete$.subscribe((layer: Layer) => {
				this.canvasService.canvas.setActiveObject(this.canvasService.canvas.getObjects().find((obj) => obj.layerId === layer.id));
				this.canvasService.canvas.renderAll();
				this.undoRedoService.recordState('Add image layer');
			})
		);
	}

	onLayerDelete(): void {
		this.subs.push(
			this.layersService.layerDelete$.subscribe((layerId: number) => {
				this.deleteObj(layerId);
			})
		);
	}

	onLayerReorder(): void {
		this.subs.push(
			this.layersService.layerReorder$.subscribe((layers: Layer[]) => {
				let arr = [];
				const objs = this.canvasService.canvas.getObjects();

				//Keep object layer id's in sync with current layer id
				objs.forEach((obj, i) => {
					const layer = layers.find((layer) => layer.idPreDrag === obj.layerId);
					obj.layerId = layer.id;
					arr.push(layers.slice(-1 - i)[0]);
				});
				arr = this.canvasService.omitDuplicateLayerId(arr, 'id');
				arr
					.filter((layer) => layer.id >= 0)
					.forEach((layer: Layer, i) => {
						const obj = objs.find((obj) => obj.layerId === layer.id);
						obj.moveTo(i);
						obj.setCoords();
					});
				this.canvasService.canvas.renderAll();
			})
		);
	}

	onTimelineLayerSelect(): void {
		this.subs.push(
			this.layersService.activeLayerSelected$.subscribe((payload) => {
				if (payload) {
					const [layerId, component, groupedObjects]: [number, string, any] = payload;

					if (!groupedObjects) {
						//0 is the background layer
						if (layerId !== null && layerId !== -1 && component !== 'canvas') {
							let obj = this.canvasService.canvas.getObjects().find((obj) => {
								//When selecting feed image, return the bounding box
								if (obj.layerId === layerId && obj.isFeedImage) {
									return obj.isFeedImageBoundingBox;
								}
								//If selecting feed text, return text box, not hidden feed text image
								return obj.layerId === layerId && !obj.isFeedTextImage;
							});
							this.canvasService.canvas.setActiveObject(obj);
							this.canvasService.canvas.renderAll();
						}
					}
				}
			})
		);
	}

	private deleteObj(layerId: number): void {
		//If feed, two objects will have the same layerId...visible image, and hidden textbox
		this.canvasService.canvas
			.getObjects()
			.filter((obj) => obj.layerId === layerId)
			.forEach((obj) => {
				this.canvasService.canvas.remove(obj);
			});
		this.syncObjectIdsOnDelete(layerId);
	}

	private syncObjectIdsOnDelete(layerId: number): void {
		this.canvasService.canvas.getObjects().forEach((obj) => {
			//Sync all objects greater than deleted object
			if (obj.layerId > layerId) {
				obj.layerId = obj.layerId - 1;
			}
			obj.setCoords();
		});
		this.canvasService.canvas.renderAll();
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.cEventsService.subs.forEach((sub) => sub.unsubscribe());
		if (this.videoControlsService.videoPlayer) {
			this.videoControlsService.videoPlayer.nativeElement.currentTime = 0;
		}
	}
}
