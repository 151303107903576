import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpBackend, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { map, mergeAll, switchMap, tap } from 'rxjs/operators';
import { NgxTippyService } from 'ngx-tippy-wrapper';

import { AdminDataService, AppStateService, BrowserDetectorService, ClientSwitcherService, SecurityService, CacheService } from '../core/services';
import { Clients, Players, ProductTypes, Users } from '../shared/api-models/admin';
import { environment } from '../../environments/environment';
import { Alert, LoginView } from './_models/login-models';

@Injectable({ providedIn: 'root' })
export class LoginService {
	public alert: Alert;
	public forgotPasswordMessage: string;
	public passwordPattern = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~\\]])\\S{16,}$');
	public userName: string;
	public viewState: LoginView;
	public showRevealPasswordIcon: boolean;
	public passwordInputType: 'text' | 'password' = 'password';
	public tfaImageUrl: string;
	public manualEntryCode: string;

	get eyeIconTooltip() {
		return this.passwordInputType === 'password' ? 'Show password' : 'Hide password';
	}

	constructor(
		private adminDataService: AdminDataService,
		private appStateService: AppStateService,
		private browserDetectorService: BrowserDetectorService,
		private clientSwitcherService: ClientSwitcherService,
		private handler: HttpBackend,
		private httpClient: HttpClient,
		private ngxTippyService: NgxTippyService,
		private router: Router,
		private securityService: SecurityService,
		private cacheService: CacheService
	) {}

	public authenticateUser(username: string, password: string): Observable<{ tfaImageUrl: string; manualEntryCode: string } | [null, null]> {
		const credentials = {
			username: username,
			password: password
		};
		return this.httpClient.post<any>(`${environment.authUrl}login`, credentials, { observe: 'response' }).pipe(
			switchMap((res: HttpResponse<any>) => {
				const token = res.headers.get('w24-token');

				if (token) {
					sessionStorage.setItem('authToken', token);
					sessionStorage.setItem('awaitingPin', 'true');
				}
				this.appStateService.setSessionItem('currentUser', JSON.stringify(res.body));
				this.appStateService.setClientAndUser();

				return forkJoin(this.observs$(res)).pipe(
					tap((res: [Clients[], ProductTypes[], Players[], [Users]]) => {
						const [currentClient, contractedProducts, availableVideoPlayers, [csrByClient]] = res;
						const filteredContractedProducts = contractedProducts.filter((p) => p.hasContract === 1);
						this.appStateService.setSessionItem('clientHasAppleTvs', JSON.stringify(availableVideoPlayers.some((player) => player.PlayerModelId === 1000000)));
						this.appStateService.setClientHasAppleTvs();
						this.appStateService.setSessionItem('currentClient', JSON.stringify(currentClient));
						this.appStateService.setClientAndUser();
						this.appStateService.setSessionItem('contractedProducts', JSON.stringify(filteredContractedProducts));
						this.appStateService.setSessionItem('csrByClient', JSON.stringify(csrByClient));
						this.appStateService.setContractedProducts();
						this.appStateService.setCsr();
						this.cacheService.userCache = [];
						this.cacheService.ticketStatesCache = [];
						if (this.browserDetectorService.browserIsIE()) {
							this.sendEmail(this.clientUsingIeEmail());
						}
					}),
					switchMap(() => {
						if (res.body.IsEmployee) {
							return combineLatest([
								this.httpClient.get(`${environment.authUrl}Tfa`, { responseType: 'blob' }).pipe(map((blob) => URL.createObjectURL(blob))),
								this.httpClient.get(`${environment.authUrl}Tfa/ManualEntryCode`, { responseType: 'text' })
							]);
						} else {
							return of([null, null]);
						}
					}),
					map(([tfaImageUrl, manualEntryCode]) => {
						return { tfaImageUrl, manualEntryCode };
					})
				);
			})
		);
	}

	private observs$(res: HttpResponse<any>): any[] {
		const currentClient$ = this.httpClient.get(`${environment.adminUrl}CoreClients/${res.body.ClientId}`);
		const contractedProducts$ = this.adminDataService.getContractedProducts$(res.body.ClientId);
		const availableVideoPlayers$ = this.httpClient.get(
			`${environment.adminUrl}CorePlayers/AvailableVideoPlayers?clientId=${res.body.ClientId}&userId=${this.appStateService.currentUser.UserId}`
		);
		const csrByClient$ = this.httpClient.get(`${environment.adminUrl}CoreClients/${res.body.ClientId}/Csr`);

		if (this.appStateService.currentUser.Role === 'Video Content Contractor') {
			return [currentClient$, contractedProducts$];
		}
		return [currentClient$, contractedProducts$, availableVideoPlayers$, csrByClient$];
	}

	public routeOnLoginSuccess() {
		const userRole = this.appStateService.currentUser.Role;

		if (userRole === 'Salesperson') {
			//Somewhat arbitrary url here, if just naving to the dashboard it won't reload
			this.router.navigate(['/crm/client-details/locations/12622']);
			localStorage.setItem('changeClientUrl', `/my-products`);
			this.clientSwitcherService.onClientChange({ CsrId: 34, Id: 4582, Name: 'Works24 Demo Account' });
			return;
		}
		switch (true) {
			//Route content creators directly to Content Requests
			case this.securityService.userRole(userRole) === 'contentcreator':
			case userRole === 'Script Writer':
				this.router.navigate(['/system-tools/content-requests']);
				break;
			//Route Technicians directly to Ticket System
			case userRole === 'Technician':
				if (this.userRoutingToUnAuthUrl()) {
					this.navToColdUrl();
					return;
				}
				this.router.navigate(['/ticket-system']);
				break;
			//Route CSRs to CSR Home
			case userRole === 'CSR':
				this.router.navigate([`/csr-home/user-id/${this.appStateService.currentUser.UserId}`]);
				break;
			default:
				this.navToDashOrColdUrl();
		}
	}

	private navToDashOrColdUrl(): void {
		if (this.userRoutingToUnAuthUrl()) {
			this.navToColdUrl();
			return;
		}
		this.router.navigate(['/my-products']);
	}

	private navToColdUrl(): void {
		const unAuthUrl: string = JSON.parse(this.appStateService.getSessionItem('unauthorizedUrl'));
		//Don't allow cold navs to the crm
		if (!unAuthUrl.includes('crm')) {
			//was the user navigating cold to a url without being logged in?
			this.router.navigate([`/${JSON.parse(this.appStateService.getSessionItem('unauthorizedUrl'))}`]);
		} else {
			this.router.navigate(['/my-products']);
		}
		this.appStateService.removeSessionItem('unauthorizedUrl');
	}

	private userRoutingToUnAuthUrl(): boolean {
		return this.appStateService.getSessionItem('unauthorizedUrl') && JSON.parse(this.appStateService.getSessionItem('unauthorizedUrl')).length > 1;
	}

	public onPasswordInputMouseover(showRevealPasswordIcon: boolean): void {
		this.showRevealPasswordIcon = showRevealPasswordIcon;
	}

	private setTippyContent(tooltipName: string): void {
		const tippyInstance = this.ngxTippyService.getInstance(tooltipName);
		if (tippyInstance) {
			tippyInstance.setContent(this.eyeIconTooltip);
		}
	}

	public onShowPasswordClick(tooltipName: string): void {
		this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
		this.setTippyContent(tooltipName);
	}

	public onShowPasswordClickMobile(tooltipName: string): void {
		this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
		this.setTippyContent(tooltipName);
	}

	public setAlert(alert: Alert): void {
		this.alert = { ...alert };
	}

	public onPasswordResetSubmit(newPassword: string, guid: string): Observable<any> {
		const httpClient = new HttpClient(this.handler);
		return httpClient.post(`${environment.authUrl}passwordresetcomplete/`, { NewPassword: newPassword, ResetToken: guid });
	}

	public passwordResetVerify(guidToken: string): Observable<any> {
		const httpClient = new HttpClient(this.handler);
		return httpClient.get(`${environment.authUrl}passwordresetverify/${guidToken}`);
	}

	public sendPasswordResetEmail(usernameOrEmail: string): Observable<any> {
		//passing HttpBackend to HttpClient bypasses the auth interceptor,
		//so we can use the method without being authenticated
		const httpClient = new HttpClient(this.handler);
		return httpClient.get(`${environment.authUrl}passwordresetrequest/${usernameOrEmail}`);
	}

	public clientUsingIeEmail(): any {
		return {
			from: `InternetExplorerUser@works24.com`,
			recipient: this.appStateService.csrByClient.Email,
			subject: `User using Internet Explorer`,
			body:
				`${this.appStateService.currentUser.UserName} of ${this.appStateService.currentClient.Name} has logged in with Internet Explorer. \n \n` +
				`Internet Explorer will soon be unsupported. Please contact this client and see if they can switch to Chrome, Edge, Firefox or Safari.`
		};
	}

	public sendEmail(params: any): void {
		const obj = {
			To: this.appStateService.csrByClient.Email,
			Cc: 'aharms@works24.com',
			From: params.from,
			Subject: params.subject,
			Body: params.body
		};
		//send email
		this.httpClient.post(environment.adminUrl + 'Email', obj).subscribe();
	}

	public onQrPinSubmit$(pin: number): Observable<Object> {
		const params = new HttpParams().set('pin', pin);

		return this.httpClient.post(`${environment.authUrl}Tfa`, params.toString(), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}

	public updateShow2faQRCode(): Observable<Object> {
		return this.httpClient.patch(`${environment.adminUrl}CoreUsers/${this.appStateService.currentUser.UserId}`, { Show2faQRCode: false });
	}
}
